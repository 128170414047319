.swal-button {
    background-color: #003D7B;
}

.swal-button:not([disabled]):hover {
    background-color: #003D7B;
}

.btn-cancel {
    color: white !important;
}
